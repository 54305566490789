import React, { Component } from "react"
import { graphql, Link } from 'gatsby'
import Modal from "react-modal"
import _ from "lodash"

import Layout from '../../components/layout'
import SEO from '../../components/seo'

import algoliasearch from 'algoliasearch/lite'

import {
  InstantSearch,
  Hits,
  SearchBox,
  Pagination,
  RefinementList,
  Configure,
  SortBy,
} from 'react-instantsearch-dom'
import PropTypes from 'prop-types'

import { saveAs } from "file-saver";

import FigureBox, { boxFormats } from "../../components/FigureBox"

import Close from "../../svg/Close"
import Div100vh from "../../utils/Div100vh"

const FlickityOld = typeof window !== `undefined` ? require(`flickity`) : undefined

// init modal element
Modal.setAppElement(`#___gatsby`)

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APPLICATION_ID,
  process.env.GATSBY_ALGOLIA_API_KEY
);

class CultureConIndex extends Component {
  constructor(props) {
    super(props)
    this.state = {
      quickLookMode: false,
      modalIsClosing: false
    }

    this.data = this.props.data.allContentfulCultureCon
    // console.log(this.data)

    // holding modal slider
    this.slider = null

    // event for toggling modal with quickview mode
    this.toggleMode = this.toggleMode.bind(this)
  }

  listMedias() {
    let slides = []

    {this.data.edges.map((ccon, index) => {
      return(
       slides.push(
        <FigureBox
          source={ccon.node.personImage}
          format={boxFormats.auto}
          backgroundPosition={`center center`}
          backgroundSize="contain"
        />
      )
    )})}

    return slides.map((slide, index) => (
      <div key={index} className="work__single__quickview__slide">
        <div className="inner">{slide}</div>
      </div>
    ))
  }

  initModal() {
    this.slider = new FlickityOld(this.refs.slider, {
      cellAlign: `left`,
      draggable: true,
      wrapAround: true,
      percentPosition: true,
      contain: false,
      pageDots: false,
      selectedAttraction: 0.085,
      friction: 0.65,
    })
  }

  toggleMode(e = null, modalIndex = 0) {

    if (e) e.preventDefault()
    this.setState({
      quickLookMode: !this.state.quickLookMode ? true : false,
      modalIsClosing: false,
      modalIndex: modalIndex,
    })
  }

  closeModal(e = null) {
    if (e) e.preventDefault()
    if (!this.state.modalIsClosing) {
      this.setState({
        modalIsClosing: true,
      })
      setTimeout(() => {
        this.toggleMode(e)
      }, 1000)
    }
  }

  modalClassName(base = `modal`) {
    let classes = [base]

    if (this.state.modalIsClosing) {
      classes.push(`is-closing`)
    }

    return classes.join(` `)
  }

  render() {
    return (
      <Layout>
        <SEO title={`Culture Con`} description={`Culture Con`} />
        <section className="qa_index container-fluid">

          <Modal
            className={this.modalClassName(`modal__main`)}
            overlayClassName={this.modalClassName(`modal__overlay`)}
            onAfterOpen={e => this.initModal()}
            onRequestClose={e => this.closeModal(e)}
            isOpen={this.state.quickLookMode}
          >
            <Div100vh>
              <div className="modal__inner">
                <a
                  href
                  className="modal__close"
                  onClick={e => this.closeModal(e)}
                >
                  <Close />
                </a>
                <header className="modal__header is-text-center">
                  {this.data.edges.map((ccon, index) => (
                    <h5 className="no-margin">{ccon.personName}</h5>
                  ))}
                </header>
                <div className="modal__content">
                  <div ref="slider" className="work__single__quickview">
                    {this.listMedias()}
                  </div>
                </div>
              </div>
            </Div100vh>
          </Modal>

          <header className="single__header">
            <p className="h4 text-center">
              <Link to="/culturecon/">CultureCon</Link>
            </p>
          </header>

          {/* top header intro */}
          <div className="row">
            <header className="qa_index__header_intro cul__con__parent" style={{margin: "0 auto"}}>
              {/* intro text */}
              <hgroup className="row mb-4">
                <h1 className="col-12">CultureCon</h1>
                <div className="row justify-content-center">
                <div className="col-lg-6">
                <p className="col-12 mb-2">Hey! It was cool meeting you at CultureCon. While you’re here...</p>
                <p className="col-12 mb-2">Want to learn what we’re about? Check out our <Link to="/" className="text-underline">website</Link>. Looking for a job? We got you! Check out our <Link to="/jobs">jobs page</Link>. You can also find us on <a href="https://www.linkedin.com/company/wieden---kennedy" target="_blank" className="text-underline">LinkedIn</a>, <a href="https://www.instagram.com/wiedenkennedy/?hl=en" target="_blank" className="text-underline">Instagram</a>, and <a href="https://twitter.com/WiedenKennedy?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor" target="_blank" class="text-underline">Twitter</a> @WiedenKennedy.</p>
                <p className="col-12 mb-2">Thank you! If you have any questions regarding your photos + more, email: <a href="mailto:eno.oduok@wk.com" className="text-underline">eno.oduok@wk.com</a></p>
                <p className="col-12 mb-1">Photographers: Ari Skin (<a href="https://www.instagram.com/ariskin/" target="_blank" className="text-underline">@ariskin</a>) & Lexi Scott (<a href="https://www.instagram.com/colorway_studios/" target="_blank" className="text-underline">colorway_studios</a>)</p>
                </div>
                </div>
              </hgroup>
            </header>
          </div>
        <div className="ais-InstantSearch">
          <InstantSearch indexName={`${process.env.GATSBY_ALGOLIA_INDEX_PREFIX}${process.env.GATSBY_ALGOLIA_INDEX_NAME}`} searchClient={searchClient}>
          <div className="left-panel" style={{ display: 'none' }}>
              <RefinementList attribute="contentType" defaultRefinement={['culturecon']} />
              <Configure 
                hitsPerPage={20}  
              />
            </div>
            <div className="right-panel">
              <SearchBox placeholder="Seach your name" />
              <Hits hitComponent={Hit} />
              <Pagination />
            </div>
          </InstantSearch>
        </div>

        </section>
      </Layout>
    )
  }
}

function Hit(props) {
  const saveFile = () => {
    saveAs(
      props.hit.downloadMainImage.file.url,
      props.hit.title
    );
  };
  return (
    <div className="qa__preview cul__con">
      <header>
      <FigureBox
        format={boxFormats.portrait}
        type="background"
        source={props.hit.mainImage}
      />
      </header>
      <footer className="mt-2">
        <h4 className="user-name">{props.hit.title}</h4>
        <div className="download-icon-cc">
          <a href='javascript:;' onClick={saveFile}><svg viewBox="0 0 33 36" ><path d="M27.9902 16.38L25.8302 14.22L17.7302 22.365V0H14.6702V22.365L6.57016 14.22L4.41016 16.38L16.2002 28.215L27.9902 16.38Z" /><path d="M29.34 24.525V32.94H3.06V24.525H0V36H32.4V24.525H29.34Z" /></svg></a>
          </div>
      </footer>
    </div>
  );
}

Hit.propTypes = {
  hit: PropTypes.object.isRequired,
};

export default CultureConIndex


export const query = graphql`
  query CultureConIndex {
    allContentfulCultureCon(sort: {fields: personName}) {
      edges {
        node {
          personName
          personNameSlug
          downloadImage{
            gatsbyImageData(width: 1920, quality: 80)
          }
          personImage {
            gatsbyImageData(width: 1000, quality: 80)
          }
        }
      }
    }
  }
`